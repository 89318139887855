import { IAddressObject } from "../../util/functions/parseGeocodeLocationToAddress";
import { CheckoutForm as CheckoutFormA } from "./CheckoutForm.a";
import { CheckoutForm as CheckoutFormB } from "./CheckoutForm.b";

export interface CheckoutFormProps {
  address?: IAddressObject;
  nextStep: () => void;
  noBackButton?: boolean;
  propertyAddress: IAddressObject;
  showSidebar?: boolean;
}

export const CheckoutForm = (props: CheckoutFormProps) => {
  const urlParams = new URLSearchParams(window.location.search);
  const byProxy = urlParams.get("byProxy") === "true";

  return byProxy ? (
    <CheckoutFormB {...props} byProxy={byProxy} />
  ) : (
    <CheckoutFormA {...props} />
  );
};
