import { useHistory } from "react-router";
import {
  CONTACT_INFO_PATH,
  HOME_ADDRESS_PATH,
} from "../../pages/designProfile/constants/paths";
import { checkForAddress } from "../../pages/designProfile/util/checkForAddress";
import {
  DESIGN_PROFILE_VERSION_1,
  DESIGN_PROFILE_VERSION_9,
} from "../../util/constants/designProfileVersionIds";
import { useDesignProfileCtx } from "./DesignProfileCtx";
import { DesignProfileWithEmail } from "./DesignProfileWithEmail";

interface DesignProfileRouteAccessProps {
  children?: any;
}

export const DesignProfileRouteAccess = ({
  children,
}: DesignProfileRouteAccessProps) => {
  const {
    designProfile,
    isFetching,
    designProfileVersionId,
  } = useDesignProfileCtx();
  const history = useHistory();

  // if v1 design profile: restrict paths (except for CONTACT_INFO PATH) unless email is entered
  // restrict paths (except for CONTACT_INFO_PATH and HOME_ADDRESS_PATH) unless address is entered
  if (
    designProfileVersionId === DESIGN_PROFILE_VERSION_9 ||
    designProfileVersionId === DESIGN_PROFILE_VERSION_1
  ) {
    if (window.location.pathname === `/design-profile/${CONTACT_INFO_PATH}`) {
      return children;
    } else if (
      window.location.pathname === `/design-profile/${HOME_ADDRESS_PATH}`
    ) {
      return <DesignProfileWithEmail>{children}</DesignProfileWithEmail>;
    } else {
      checkForAddress({
        designProfile,
        history,
        isDesignProfileFetching: isFetching,
      });
      return <DesignProfileWithEmail>{children}</DesignProfileWithEmail>;
    }
  }

  // else if v2 design profile: restrict paths (except for HOME_ADDRESS_PATH) unless address is entered
  if (window.location.pathname === `/design-profile/${HOME_ADDRESS_PATH}`) {
    return children;
  }

  checkForAddress({
    designProfile,
    history,
    isDesignProfileFetching: isFetching,
  });

  return children;
};
