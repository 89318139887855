// leave this here commented out (even if no tests being run) as an example for next design profile version
import Cookies from "js-cookie";
import { DesignProfileVersionId } from "../../types/DesignProfile";
import {
  DESIGN_PROFILE_VERSION_1,
  DESIGN_PROFILE_VERSION_10,
  DESIGN_PROFILE_VERSION_12,
  DESIGN_PROFILE_VERSION_2,
  DESIGN_PROFILE_VERSION_3,
  DESIGN_PROFILE_VERSION_4,
  DESIGN_PROFILE_VERSION_5,
} from "../constants/designProfileVersionIds";

/**
 * Returns the current version of the Design Profile for the client being used
 * Will return null if client is not ready yet
 * This function should only be used when passing a version to the DesignProfileProvider (with a nullish check)
 * When accessing the versionId inside DesignProfileProvider, use DesignProfileCtx
 */
const useCurrentDesignProfileVersion = (): DesignProfileVersionId => {
  // for cypress testing to be reliable we need to put the app in a deterministic state
  // This means we need to be able to tell it which A/B test to run via cookies
  // Documentation: https://docs.cypress.io/guides/core-concepts/conditional-testing#A-B-campaign
  const cypressCookieValue = Number(Cookies.get("cypressDesignProfileVersion"));

  if (cypressCookieValue === 1) return DESIGN_PROFILE_VERSION_1;
  if (cypressCookieValue === 2) return DESIGN_PROFILE_VERSION_2;
  if (cypressCookieValue === 3) return DESIGN_PROFILE_VERSION_3;
  if (cypressCookieValue === 4) return DESIGN_PROFILE_VERSION_4;
  if (cypressCookieValue === 5) return DESIGN_PROFILE_VERSION_5;

  // This section is for determining if the link to the profile quiz was provided an email address
  const quizEmailCookie = Cookies.get("yzQuizEmail") ? true : false;

  if (quizEmailCookie === true) return DESIGN_PROFILE_VERSION_12;

  return DESIGN_PROFILE_VERSION_10;
};

export { useCurrentDesignProfileVersion };
