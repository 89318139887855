import { Box, Grid, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import * as React from "react";
import LowesIcon from "../../../src/images/lowes.svg";
import { useAppSelector } from "../../hooks";
import {
  imageUrlForPackage,
  LOWES_PACKAGE,
  UBER_PREMIUM_DISPLAY_NAME,
  UBER_PREMIUM_PACKAGE,
} from "../../util/constants/packageTypes";
import { usePremiumPackage } from "../../util/hooks/usePremiumPackage";
import { chooseDiscountDisplayPrice } from "./chooseDiscountDisplayPrice";
import { ExpeditedUpsell } from "./ExpeditedUpsell";
import { HOAExtraRevisionUpsell } from "./HOAExtraRevisionUpsell";
import PackageDisplayName from "./PackageDisplayName";
import RenderPrice from "./RenderPrice";
import { promoCodeIncludesFreeExpedited } from "./util/promoCodeIncludesFreeExpedited";
import { promoCodeIsForUpgrade } from "./util/promoCodeIsForUpgrade";

export interface InvoiceItemProps {
  title: string;
  subtitle: string;
  price: number; // cents
  quantity: number;
  modifiedPrice: null | number;
  hideExpeditedUpsell?: boolean;
  hideHOAExtraRevisionUpsell?: boolean;
  hideTotal?: boolean;
  titleOverride?: string | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  circleNumber: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(191, 204, 191, 1)",
    fontSize: 11,
    fontWeight: "bold",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "1rem",
    lineHeight: "100%",
    position: "absolute",
    left: "-0.5rem",
    top: "-0.5rem",
  },
  itemPhoto: {
    position: "relative",
  },
  renderImage: {
    borderRadius: 4,
    width: "100%",
    height: 100,
    backgroundSize: "cover",
  },
}));

export const InvoiceItem = ({
  title,
  subtitle,
  price,
  quantity,
  modifiedPrice,
  hideExpeditedUpsell = false,
  hideHOAExtraRevisionUpsell = false,
  hideTotal = false,
  titleOverride = null,
}: InvoiceItemProps) => {
  const classes = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { addOnsTotal, products } = useAppSelector(state => state.products);
  const { promotionalCode } = useAppSelector(state => state.checkout);
  const { originalOrderPrice, orderPrice } = useAppSelector(
    state => state.payment
  );
  const { isPremium } = usePremiumPackage();

  const isLowesPackage = subtitle.includes("Lowes");

  const promoCodeIsExpedited =
    (promotionalCode &&
      promoCodeIncludesFreeExpedited(promotionalCode, products)) ??
    false;

  const hasPromoCodeForUpgrade =
    (promotionalCode && promoCodeIsForUpgrade(promotionalCode)) ?? false;

  const clientDerivedPrice = addOnsTotal + price;
  const packageDisplayName = React.useMemo(() => {
    let packageName = titleOverride ?? title;

    if (isLowesPackage) {
      return "MyLowe's Rewards + Yardzen Design Package";
    }

    // TODO: Get rid of magic strings
    // These names are coming from Stripe which is why they include "Package" at the end
    if (title === "Whole Home Package") {
      packageName = "Outdoor Transformation Package";
    }

    if (title.toLowerCase() === `${UBER_PREMIUM_PACKAGE} package`) {
      packageName = UBER_PREMIUM_DISPLAY_NAME;
    }

    return packageName;
  }, [titleOverride, title, isLowesPackage]);

  const subtitleDisplay = React.useMemo(() => {
    if (isLowesPackage) {
      if (title.includes("Full Yard")) {
        return "Full Yard";
      }
      return "Front or Backyard";
    }

    return subtitle;
  }, [subtitle, title, isLowesPackage]);

  function getBackgroundImage() {
    if (isLowesPackage) {
      return imageUrlForPackage[LOWES_PACKAGE];
    }

    let productName = title.split(" ");
    // Remove the word "Package"
    productName = productName.filter(word => word !== "Package");
    const packageName = isPremium
      ? "premium"
      : productName.join(" ").toLowerCase();

    return imageUrlForPackage[packageName];
  }

  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        {!smDown && (
          <Grid item xs={3}>
            <Box className={classes.itemPhoto}>
              <div
                style={{
                  backgroundImage: `url(${getBackgroundImage()})`,
                }}
                className={classes.renderImage}
              />
              <Box className={classes.circleNumber} mr={1}>
                {quantity}
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                flexDirection: "column",
                gap: "18px",
              }}
            >
              {isLowesPackage && <img src={LowesIcon} alt="Lowes" />}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <PackageDisplayName
                  packageDisplayName={packageDisplayName}
                  hasPromoCodeForUpgrade={hasPromoCodeForUpgrade}
                  variant="h4"
                />
                <RenderPrice originalPrice={price} finalPrice={null} />
              </div>
            </div>
            <YZTypography color="textSecondary" variant="body2">
              {subtitleDisplay}
            </YZTypography>
          </div>
          {!hideExpeditedUpsell && (
            <ExpeditedUpsell promoCodeIsExpedited={promoCodeIsExpedited} />
          )}
          {!hideHOAExtraRevisionUpsell ? <HOAExtraRevisionUpsell /> : null}
        </Grid>
        {!hideTotal && (
          <Box width="100%" display="flex" flexDirection="column">
            {promotionalCode && (
              <Box width="100%" display="flex" justifyContent="flex-end">
                <YZTypography variant="body2">
                  Promotional Code {promotionalCode.code} applied!
                </YZTypography>
              </Box>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 10,
                alignItems: "baseline",
              }}
            >
              <YZTypography
                style={{ marginRight: 10 }}
                type="serif"
                variant="h4"
              >
                Total
              </YZTypography>
              <RenderPrice
                originalPrice={originalOrderPrice ?? clientDerivedPrice}
                finalPrice={chooseDiscountDisplayPrice(
                  orderPrice,
                  originalOrderPrice,
                  modifiedPrice
                )}
              />
            </div>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default InvoiceItem;
